import React from "react";
import { Link } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import Layout from "../components/layout";
import FormerSuccess from "../components/farmersuccess";
import ModalVideo from 'react-modal-video';
import DonutChart from "react-donut-chart";
require("../css/home.css");
require("../css/how-it-works.css");
require("../css/responsive.scss");

require("../css/responsive.scss");

class HowitWorks extends React.Component {

  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this)
  }

  openModal() {
    this.setState({ isOpen: true })
  }

  render() {
    return (
      <Layout>
        <div className="howitWorksbanner">
          <div className="col-sm-12">
            <h3 className="hitWorksHead">
              Connecting farmers to the consumers
            </h3>
            <p className="hitWorkssubHead">
              Native Circle intends to bridge the gap between the producers and
              consumers.
            </p>
            <div className="col-sm-6 hitWorksPoints">
              <div className="col-sm-12">
                <div className="row">
                  <div className="col-sm-4">
                    <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1565276859/group-48-min_loy24v.png" />
                    <p className="hitWorksBanInfo">100% Organic</p>
                  </div>
                  <div className="col-sm-4">
                    <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1565276799/group-25-min_ptu73k.png" />
                    <p className="hitWorksBanInfo">Transparent pricing</p>
                    <hr className="hrLine" align="center" />
                  </div>
                  <div className="col-sm-4">
                    <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1551164801/Native%20Circle/group-27.png" />
                    <p className="hitWorksBanInfo">Value for money</p>
                    <hr className="hrLine" align="center" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container pad0">
          <div className="col-sm-12 pad0 connectFarmerMain">
            <h5 className="connectFarmerHead">
              <span className="connectFarmerHeadSpan">
                The transparent chain of
              </span>{" "}
              production to consumption
            </h5>
            <div className="row">
              <div className="col-sm-7">
                <div className="hwItWorks">{""}</div>
              </div>
              <div className="col-sm-5">
                <h5 className="farmerTransparent">
                  Transparant Value for Ethical Farming
                </h5>
                <p className="farmerTransparentInfo">
                  Native Circle makes sure that the farmers knows where his
                  produces reach with a reasonable value and the Consumers know
                  where the products are sourced from and how they were grown.
                  To meet the ecological responsibilty, Native Circle uses only
                  ecofriendly Packaging...
                </p>
                <br />
                <div className="media">
                  <div className="media-left">
                    <img
                      src="https://res.cloudinary.com/djoztpm50/image/upload/v1565276472/group-28-min_twhzi9.png"
                      className="media-object"
                    />
                  </div>
                  <div className="media-body">
                    <p className="farmerPointList">
                      Products from ethically harvested source
                    </p>
                  </div>
                </div>

                <div className="media">
                  <div className="media-left">
                    <img
                      src="https://res.cloudinary.com/djoztpm50/image/upload/v1565276472/group-28-min_twhzi9.png"
                      className="media-object"
                    />
                  </div>
                  <div className="media-body">
                    <p className="farmerPointList">
                      Ecologically responsible Packaging and Practices
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>




        <div className="pricing_section  col-sm-12">
          <div className="col-sm-12 pad0 ">
            <h5 className="connectFarmerHead">
              <span className="connectFarmerHeadSpan">
                CREATE ORGANIC SOCIETY
              </span>{" "}
              {/* Explore organic products. */}
            </h5>
          </div>

          <div className="row">
            <div className="col-sm-6 col-md-6 commitmentFirstSec howitworksPricingBg addBackgrd" id="desc-create-org">
              <div className="col-md-12">
                <p className="commitmentMain">
                  {/*  Inspiring and Training */}
                    Explore Organic Products
                </p>
                <p className="commitmentSub">
                  {/* Extensive Farming Knowledge Database{" "} */}
                  {/* Explore organic products */}
                </p>
                <p className="empoweringContent">
                  Each and every product in Native Circle passes through many
                  hands before coming to you. When you buy our products, you
                  touch many lives thus becoming a part of the story itself.
                  Each product comes with a cost break-up where the customer is
                  aware of the many sources from which the product was created
                  and how much each source receives from the customers end.
                </p>
                <div className=" btnMainShop">
                  <Link to="/products">
                    <button className="otherButtons">
                      Shop now{" "}
                      <img
                        className="watchVideoIcon"
                        src="https://res.cloudinary.com/djoztpm50/image/upload/v1551866451/Native%20Circle/path_1.svg"
                      />
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-sm-6 col-md-6 commitmentFirstSec addBackgrd1" id="mob-create-org">
              <div className="farmerBannerTxt">
                <p className="commitmentMain">
                  {/*  Inspiring and Training */}
                    Explore Organic Products
                </p>
                <p className="commitmentSub">
                  {/* Extensive Farming Knowledge Database{" "} */}
                  {/* Explore organic products */}
                </p>
                <p className="empoweringContent">
                  Each and every product in Native Circle passes through many
                  hands before coming to you. When you buy our products, you
                  touch many lives thus becoming a part of the story itself.
                  Each product comes with a cost break-up where the customer is
                  aware of the many sources from which the product was created
                  and how much each source receives from the customers end.
                </p>
                <div className=" btnMainShop">
                  <Link to="/products">
                    <button className="otherButtons">
                      Shop now{" "}
                      <img
                        className="watchVideoIcon"
                        src="https://res.cloudinary.com/djoztpm50/image/upload/v1551866451/Native%20Circle/path_1.svg"
                      />
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            {/* 
            <div className="col-md-1"></div> */}

            <div className="col-xs-6 col-md-6 col-sm-6  ">
              <div className="row col-sm-12 NC-flex-div pad0">
                <div
                  className="NC-flex-divs"
                  style={{ backgroundColor: "#5e6369" }}
                />
                <div
                  className="NC-flex-divs"
                  style={{ backgroundColor: "#a3996e" }}
                />
                <div
                  className="NC-flex-divs"
                  style={{ backgroundColor: "#250c20" }}
                />
                <div
                  className="NC-flex-divs"
                  style={{ backgroundColor: "#ccaa91" }}
                />
                <div
                  className="NC-flex-divs"
                  style={{ backgroundColor: "#491f0f" }}
                />
                <div
                  className="NC-flex-divs"
                  style={{ backgroundColor: "#f2ba48" }}
                />
              </div>
              <div className="NC-background">
                <div className="row">
                  <div className="col-sm-5 col-md-5 col-12 graph-div">
                    <div>
                      <DonutChart
                        data={[
                          {
                            label: "Pay for farmers",
                            value: 185.0,
                            className: "colorr",
                            outerRadius: 0.7,
                            innerRadius: 0.7,
                            clickToggle: false
                          },
                          {
                            label: "Raw materials",
                            value: 130.0,
                            outerRadius: 0.7,
                            className: "colorr",
                            innerRadius: 0.7
                          },
                          {
                            label: "Impact partners",
                            value: 135.0,
                            outerRadius: 0.7,
                            innerRadius: 0.7,
                            className: "colorr"
                          },
                          {
                            label: "Fulfillment",
                            value: 14.0,
                            outerRadius: 0.7,
                            innerRadius: 0.7,
                            className: "colorr"
                          },
                          {
                            label: "Native circle",
                            value: 76.0,
                            outerRadius: 0.7,
                            innerRadius: 0.7,
                            className: "colorr"
                          },
                          {
                            label: "GST",
                            value: 29.0,
                            outerRadius: 0.7,
                            innerRadius: 0.7,
                            className: "colorr"
                          }
                        ]}
                        colors={[
                          "#a3996e",
                          "#ccaa91",
                          "#f1ba48",
                          "#5e6369",
                          "#491f0f",
                          "#250c20",
                          "#03a9f4",
                          "#00bcd4",
                          "#009688",
                          "#4caf50",
                          "#8bc34a",
                          "#cddc39",
                          "#ffeb3b",
                          "#ffc107",
                          "#ff9800",
                          "#ff5722",
                          "#795548",
                          "#607d8b"
                        ]}
                        innerRadius={0.8}
                        outerRadius={0.95}
                        selectedOffset={0}
                        strokeColor="#ffffff"
                      />
                    </div>
                  </div>
                  <div className=" col-sm-7 col-md-7 col-12 ">
                    <ul className="NC-chart-ul">
                      <li className="NC-chart-list1">
                        <span className="NC-chart-span1">
                          &#8377;&nbsp;185.00
                        </span>
                        <span className="NC-chart-bold">
                          {" "}
                            - Pay for farmers
                        </span>
                        <br />
                        <p className="NC-chart-para">
                          Profit for artisan collective
                        </p>
                      </li>
                      <li className="NC-chart-list2">
                        <span className="NC-chart-span2">
                          &#8377;&nbsp;130.00
                        </span>
                        <span className="NC-chart-bold"> - Raw materials</span>
                        <br />
                        <p className="NC-chart-para">Yarn & natural dyes</p>
                      </li>
                      <li className="NC-chart-list3">
                        <span className="NC-chart-span3">
                          &#8377;&nbsp;135.00
                        </span>
                        <span className="NC-chart-bold"> - Impact partner</span>
                        <br />
                        <p className="NC-chart-para">
                          Training, admin cost & support services
                        </p>
                      </li>
                      <li className="NC-chart-list4">
                        <span className="NC-chart-span4">
                          &#8377;&nbsp;14.00
                        </span>
                        <span className="NC-chart-bold"> - Fulfillment</span>
                        <br />
                        <p className="NC-chart-para">
                          Packaging, payment gateway & holding inventory
                        </p>
                      </li>
                      <li className="NC-chart-list5">
                        <span className="NC-chart-span5">
                          &#8377;&nbsp;76.00
                        </span>
                        <span className="NC-chart-bold">
                          {" "}
                            - Native Circle platform
                        </span>
                        <br />
                        <p className="NC-chart-para">
                          online marketplace, product photography & others
                        </p>
                      </li>
                      <li className="NC-chart-list6">
                        <span className="NC-chart-span6">
                          &#8377;&nbsp;29.00
                        </span>
                        <span className="NC-chart-bold"> - GST</span>
                        <br />
                        <p className="NC-chart-para">
                          Goods & Services Tax India
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 pad0 HowitworksMainDiv">
          <div className="container pad0">
            <div className="col-sm-12 pad0">
              <h5 className="connectFarmerHead">
                Here's how{" "}
                <span className="connectFarmerHeadSpan">
                  our farmer support system works
                </span>
              </h5>
              <div className="row">
                <div className="col-sm-10 col-md-10 col-xs-10">
                  <h3 className="HowitworksHeading">
                    From the humble farming community to today's ecologically
                    sensitive urban consumers - here's how we come into picture.
                  </h3>
                  <p className="HowitworksHeadinginfo">
                    Native circle comes up with farmer's support system where
                    rural natural farmers are assured support from sowing to
                    reaping till marketing in a professional setup.{" "}
                  </p>
                </div>

                <div className="col-sm-2 col-md-2 col-xs-2 HITVideoClick">
                  <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={"Vv087uff3B8"} onClose={() => this.setState({ isOpen: false })} />
                  <a onClick={this.openModal}>
                    <img src="https://res.cloudinary.com/djoztpm50/image/upload/v1565277513/group-23-min_snqnjt.png" />
                  </a>
                </div>

              </div>

              <div className="row">
                <div className="col-xs-12 col-md-12 col-sm-12 textCenter">
                  <a
                    href="https://www.youtube.com/watch?v=Vv087uff3B8"
                    target="_blank"
                    className="learnmoreHIT"
                  >
                    LEARN MORE
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container pad0">
          <div className="col-sm-12 pad0 connectFarmerMain ">
            <h5 className="connectFarmerHead">
              <span className="connectFarmerHeadSpan">Our approach</span> to
              empowering farmers{" "}
            </h5>
            <div className="row">
              <div className="col-sm-4 flip-card">
                <div className="approachDivisions box1 flip-card-inner">
                  <div className="flip-card-front">
                    <div className="NC_hw_approach1 NC_hw_appdim" />
                    <h6 className="CuratedApp CAhover1">
                      Curated Video Content
                    </h6>
                    <p>Best Practices</p>
                    <p>Successful Methods</p>
                    <p>Leader Profiles</p>
                  </div>
                  <div className="box1 flip-card-back">
                    <div className="NC_hw_approach1 NC_hw_appdim" />
                    {/* <h6 className="CuratedApp CAhover1">
                      Curated Video Content
                    </h6> */}
                    <p>Succesful farmers in selected regions are profiled. Their
                    methods and practices, outlooks and inventions are documented. Hundreds of such
                    videos are made available online so that the farmers can reach out to the nearest
successful farmer around his area.</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 flip-card">
                <div className="approachDivisions box2 flip-card-inner">
                  <div className="flip-card-front">
                    <div className="NC_hw_approach2 NC_hw_appdim" />
                    <h6 className="CuratedApp CAhover2">Holistic Guidance</h6>
                    <p>Real Time Information</p>
                    <p>Info Based Recommendation</p>
                    <p>Disease Management</p>
                  </div>
                  <div className="box2 flip-card-back">
                    <div className="NC_hw_approach2 NC_hw_appdim" />
                    {/* <h6 className="CuratedApp CAhover2">Holistic Guidance</h6> */}
                    <p>Farmer is assisted to collect realtime data about his farm. Based on the data he is given recommendations about the type of crops he can grow & an overview of possible outcomes based on the weather and other factors. An overview of the market behavior is also given at the time of harvest.</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 flip-card">
                <div className="approachDivisions box3 flip-card-inner">
                  <div className="flip-card-front">
                    <div className="NC_hw_approach3 NC_hw_appdim" />
                    <h6 className="CuratedApp CAhover3">
                      Alliances & Integrations
                    </h6>
                    <p>Educators Support</p>
                    <p>Market Partners</p>
                    <p>Third Party Applications</p>
                  </div>
                  <div className="box3 flip-card-back">
                    <div className="NC_hw_approach3 NC_hw_appdim" />
                    {/* <h6 className="CuratedApp CAhover3">
                      Alliances & Integrations
                    </h6> */}
                    <p>Academic involvement is made from various universities and
                    individuals. Market alliances are struck to guarantee assured market for the farmers.
Third party applications like google are used for the real time information.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row approcahSecRow">
              <div className="col-sm-4 flip-card">
                <div className="approachDivisions box4 flip-card-inner">
                  <div className="flip-card-front">
                    <div className="NC_hw_approach4 NC_hw_appdim" />
                    <h6 className="CuratedApp CAhover4">
                      Farmer Driven Knowledge
                    </h6>
                    <p>Problem Statement Solutions</p>
                    <p>Real Time Field Data Collection</p>
                    <p>Nurturing Collectivistic Values</p>
                  </div>
                  <div className="box4 flip-card-back">
                    <div className="NC_hw_approach4 NC_hw_appdim" />
                    {/* <h6 className="CuratedApp CAhover4">
                      Farmer Driven Knowledge
                    </h6> */}
                    <p>Farmers are encouraged to state their problem areas inorder
                    to derive solutions. Farmers will assist other farmers, share their experiences and
                    insights. Farmers will generate real time field values about a particular region helping
to create a holistic information about a particular region.</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 flip-card">
                <div className="approachDivisions box5 flip-card-inner">
                  <div className="flip-card-front">
                    <div className="NC_hw_approach5 NC_hw_appdim" />
                    <h6 className="CuratedApp CAhover5">
                      Transparent Supply Chain
                    </h6>
                    <p>Producers empowerment</p>
                    <p>Customer awareness</p>
                    <p>Honest pricing</p>
                  </div>
                  <div className="box5 flip-card-back">
                    <div className="NC_hw_approach5 NC_hw_appdim" />
                    {/* <h6 className="CuratedApp CAhover5">
                      Transparent Supply Chain
                    </h6> */}
                    <p>Farmers are aware of the destiny of their products and
                    customers are aware of the origins of their products. A cost break up is provided for
                    each product so that the customers know where each of the rupee they have spent
goes.</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-4 flip-card">
                <div className="approachDivisions box6 flip-card-inner">
                  <div className="flip-card-front">
                    <div className="NC_hw_approach6 NC_hw_appdim" />
                    <h6 className="CuratedApp CAhover6">
                      Recognition of Producer
                    </h6>
                    <p>Geotag all Products</p>
                    <p>Interactions Friendly Environment</p>
                    <p>Provide Specific Datas of Fields</p>
                  </div>
                  <div className="box6 flip-card-back">
                    <div className="NC_hw_approach6 NC_hw_appdim" />
                    {/* <h6 className="CuratedApp CAhover6">
                      Recognition of Producer
                    </h6> */}
                    <p>Each producer is recognized by geotagging unique products.
                    Feild specific recommendations tailored just for that producer are provided.
                    Customers are given basic information about the producer who supplied the product
or raw materials.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 pad0 HowitworksMainDiv">
          <div className="container">
            <div className="col-sm-12 pad0">
              <h5 className="connectFarmerHead">Farmer Success Stories</h5>

              <div className="row">
                <div className="col-sm-4">
                  <FormerSuccess
                    videoid="_D8bHWicTug"
                    img="https://res.cloudinary.com/djoztpm50/image/upload/v1565172847/1-min-min_hnhj8a.png"
                    heading="Water warrior of India - Ayyappa Masagi"
                    content="Story of Ayyappa Masagi who is fighting for the cause of water conservation and management relentlessly."
                  />
                </div>

                <div className="col-sm-4">
                  <FormerSuccess
                    videoid="972nmxvRjN0"
                    img="https://res.cloudinary.com/djoztpm50/image/upload/v1565172856/2-min-min_bzmaze.png"
                    heading="Dr. Sanjeev Kulkarni of Sumana Sangama"
                    content="Dr. Sanjeev Kulkarni, a gynaecologist by profession is a versatile personality. He runs a school and has a natural farm called Sumana Sangama."
                  />
                </div>

                <div className="col-sm-4">
                  <FormerSuccess
                    videoid="SoqVI6GdZdI"
                    img="https://res.cloudinary.com/djoztpm50/image/upload/v1565172863/3-min-min_bceva1.png"
                    heading="Venkatagiri - the barefoot botanist"
                    content="Venkatagiri is a self made botanist who is working hard to conserve endangered species of rare forest and medicinal plants."
                  />
                </div>
                <div className="col-sm-12 btnMainCheckout">
                  <a
                    href="https://www.youtube.com/channel/UCY7_eKI3mv_mVCnhFFpWXEA"
                    target="_blank"
                  >
                    <button className="otherButtons">
                      Check out for more{" "}
                      <img
                        className="watchVideoIcon"
                        src="https://res.cloudinary.com/djoztpm50/image/upload/v1551866451/Native%20Circle/path_1.svg"
                      />
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default HowitWorks;
